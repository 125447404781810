import React, { useState, useEffect } from 'react';
import './App.css';
import logo from './kibomeme.png';

function App() {
  const [prompt, setPrompt] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [topText, setTopText] = useState('');
  const [bottomText, setBottomText] = useState('');
  const [remainingImages, setRemainingImages] = useState(12);
  const [remainingCaptions, setRemainingCaptions] = useState(15);
  const [recentImages, setRecentImages] = useState([]);
  const [recentCaptions, setRecentCaptions] = useState([]);
  const [selectedStyle, setSelectedStyle] = useState('None');
  const [captionLoading, setCaptionLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    const savedRemainingImages = localStorage.getItem('remainingImages');
    if (savedRemainingImages) {
      setRemainingImages(parseInt(savedRemainingImages, 10));
    }
    const savedRemainingCaptions = localStorage.getItem('remainingCaptions');
    if (savedRemainingCaptions) {
      setRemainingCaptions(parseInt(savedRemainingCaptions, 10));
    }
    const savedRecentImages = localStorage.getItem('recentImages');
    if (savedRecentImages) {
      setRecentImages(JSON.parse(savedRecentImages));
    }
    const savedRecentCaptions = localStorage.getItem('recentCaptions');
    if (savedRecentCaptions) {
      setRecentCaptions(JSON.parse(savedRecentCaptions));
    }
    const savedPrompt = localStorage.getItem('prompt');
    if (savedPrompt) {
      setPrompt(savedPrompt);
    }
    const savedImage = localStorage.getItem('image');
    if (savedImage) {
      setImage(savedImage);
    }
    const savedTopText = localStorage.getItem('topText');
    if (savedTopText) {
      setTopText(savedTopText);
    }
    const savedBottomText = localStorage.getItem('bottomText');
    if (savedBottomText) {
      setBottomText(savedBottomText);
    }
    const savedSelectedStyle = localStorage.getItem('selectedStyle');
    if (savedSelectedStyle) {
      setSelectedStyle(savedSelectedStyle);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('remainingImages', remainingImages.toString());
  }, [remainingImages]);

  useEffect(() => {
    localStorage.setItem('remainingCaptions', remainingCaptions.toString());
  }, [remainingCaptions]);

  useEffect(() => {
    if (recentImages.length > 0) {
      localStorage.setItem('recentImages', JSON.stringify(recentImages));
    }
  }, [recentImages]);

  useEffect(() => {
    localStorage.setItem('recentCaptions', JSON.stringify(recentCaptions));
  }, [recentCaptions]);

  useEffect(() => {
    localStorage.setItem('prompt', prompt);
  }, [prompt]);

  useEffect(() => {
    if (image) {
      localStorage.setItem('image', image);
    }
  }, [image]);

  useEffect(() => {
    localStorage.setItem('topText', topText);
  }, [topText]);

  useEffect(() => {
    localStorage.setItem('bottomText', bottomText);
  }, [bottomText]);

  useEffect(() => {
    localStorage.setItem('selectedStyle', selectedStyle);
  }, [selectedStyle]);

  useEffect(() => {
    fetchUsageLimits();
  }, []);

  const fetchUsageLimits = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/usage`);
      const data = await response.json();
      setRemainingImages(data.imagesRemaining);
      setRemainingCaptions(data.captionsRemaining);
    } catch (error) {
      console.error('Error fetching usage limits:', error);
    }
  };

  const generateImage = async () => {
    if (remainingImages <= 0 || isGenerating) return;
    setIsGenerating(true);
    setLoading(true);
    try {
      let modifiedPrompt = prompt.toLowerCase();
      modifiedPrompt = modifiedPrompt.replace(/kibshi|kiboshib/g, 'shiba inu puppy');
      modifiedPrompt = modifiedPrompt.replace(/sponge bob/gi, '$p0ng3 b0b');
      modifiedPrompt = modifiedPrompt.replace(/pepe/gi, 'пепе meme');
      if (!modifiedPrompt.includes('shiba inu puppy')) {
        modifiedPrompt = `${modifiedPrompt}. Shiba inu puppy`;
      }
      
      // Add style-specific text to the prompt
      switch (selectedStyle) {
        case 'Anime':
          modifiedPrompt += ". Old 1990s Anime style art. Studio Ghibli style art. Anime style art.";
          break;
        case 'Chibi':
          modifiedPrompt += ". Сute chibi style art";
          break;
        case 'Hand-Drawn':
          modifiedPrompt += ". Child's drawing with rough colorful crayon style art. Bad drawn style.";
          break;
        case 'Pixel':
          modifiedPrompt += ". Pixel style art. 8bit style.";
          break;
        case 'Simpsons':
          modifiedPrompt += ". Simpsons cartoon style art";
          break;
        case 'CCTV':
          modifiedPrompt += ". CCTV camera footage, grainy image, timestamp.";
          break;
        case 'Old Game':
          modifiedPrompt += ". Playstation 1 video game from 1998 style. Old game bad graphics style, Polygonal old game graphics style, grainy, low resolution game graphics style";
          break;
        default:
          break;
      }

      console.log('Modified prompt:', modifiedPrompt);

      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/generate-image`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: modifiedPrompt,
          style: selectedStyle,
        }),
      });

      console.log('Response status:', response.status);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log('API Response:', data);

      if (data["openai/dall-e-3"] && data["openai/dall-e-3"].items && data["openai/dall-e-3"].items.length > 0) {
        const imageUrl = data["openai/dall-e-3"].items[0].image_resource_url;
        setImage(imageUrl);
        setRemainingImages(prev => prev - 1);
        setRecentImages(prev => [imageUrl, ...prev.slice(0, 3)]);
        setRecentCaptions(prev => [{ top: topText, bottom: bottomText }, ...prev.slice(0, 3)]);
        
        // Clear text overlay fields
        setTopText('');
        setBottomText('');
      } else {
        console.error('Failed to generate image:', data);
        alert('Failed to generate image. Please try again.');
      }
    } catch (error) {
      console.error('Error generating image:', error);
      alert('Error generating image. Please check your connection and try again.');
    }
    setLoading(false);
    setIsGenerating(false);
    fetchUsageLimits();
  };

  const generateCaptions = async () => {
    if (remainingCaptions <= 0 || !image || isGenerating) return;
    setIsGenerating(true);
    setCaptionLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/generate-captions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          imageUrl: image,
        }),
      });
      const data = await response.json();
      console.log('Caption API Response:', data);
      if (data["openai/gpt-4-vision-preview"] && data["openai/gpt-4-vision-preview"].answers && data["openai/gpt-4-vision-preview"].answers.length > 0) {
        const caption = data["openai/gpt-4-vision-preview"].answers[0];
        const sentences = caption.split(/(?<=[.!?])\s+/).filter(Boolean);
        
        if (sentences.length > 1) {
          const topCount = Math.ceil(sentences.length / 2);
          setTopText(sentences.slice(0, topCount).join(' ').trim());
          setBottomText(sentences.slice(topCount).join(' ').trim());
        } else {
          // Handle long single sentence
          const words = sentences[0].split(' ');
          if (words.length > 10) { // Consider a sentence long if it has more than 10 words
            const midpoint = Math.floor(words.length / 2);
            let splitPoint = midpoint;
            
            // Try to find a punctuation mark near the midpoint to split at
            for (let i = midpoint; i < words.length; i++) {
              if (words[i].match(/[,;:]/)) {
                splitPoint = i + 1;
                break;
              }
            }
            
            setTopText(words.slice(0, splitPoint).join(' ').trim());
            setBottomText(words.slice(splitPoint).join(' ').trim());
          } else {
            setTopText('');
            setBottomText(sentences[0].trim());
          }
        }
      } else {
        console.error('Failed to generate captions:', data);
      }
    } catch (error) {
      console.error('Error generating captions:', error);
    }
    setCaptionLoading(false);
    setIsGenerating(false);
    setRemainingCaptions(prev => prev - 1);
    fetchUsageLimits();
  };

  const downloadMeme = (imageUrl, topText, bottomText) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      
      const fontSize = Math.floor(canvas.width / 24);
      ctx.font = `bold ${fontSize}px Impact`;
      ctx.fillStyle = 'white';
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 5;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'top';
      
      const wrapText = (text, maxWidth) => {
        if (!text) return []; // Return empty array if text is undefined
        const words = text.split(' ');
        const lines = [];
        let currentLine = words[0] ? words[0].toUpperCase() : '';

        for (let i = 1; i < words.length; i++) {
          const word = words[i].toUpperCase();
          const width = ctx.measureText(currentLine + " " + word).width;
          if (width < maxWidth) {
            currentLine += " " + word;
          } else {
            lines.push(currentLine);
            currentLine = word;
          }
        }
        lines.push(currentLine);
        return lines;
      };

      // Draw top text
      const topLines = wrapText(topText, canvas.width - 20);
      topLines.forEach((line, index) => {
        const y = index * fontSize + fontSize / 2;
        ctx.strokeText(line, canvas.width / 2, y);
        ctx.fillText(line, canvas.width / 2, y);
      });
      
      // Draw bottom text
      const bottomLines = wrapText(bottomText, canvas.width - 20);
      bottomLines.reverse().forEach((line, index) => {
        const y = canvas.height - (fontSize * (bottomLines.length - index)) - fontSize / 2;
        ctx.strokeText(line, canvas.width / 2, y);
        ctx.fillText(line, canvas.width / 2, y);
      });
      
      const link = document.createElement('a');
      link.download = 'meme.png';
      link.href = canvas.toDataURL();
      link.click();
    };
    img.src = imageUrl;
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="header-text">AI meme generator made completely with AI</div>
        <img src={logo} alt="Kibo Meme Logo" className="App-logo" />
        <a 
          href="https://kiboshib.com" 
          target="_blank" 
          rel="noopener noreferrer"
          className="kiboshib-link"
        >
          <span className="kiboshib-text">
            Powered by AI Meme Coin KiboShib
          </span>
          <img 
            src={`${process.env.PUBLIC_URL}/ai-meme-coin-kiboshib.png`} 
            alt="KiboShib logo" 
            className="kiboshib-logo"
          />
        </a>
      </header>
      <main className="App-main">
        <div className="image-container">
          {loading ? (
            <div className="loader">Loading...</div>
          ) : image ? (
            <div className="meme">
              <img src={image} alt="Generated meme" className="generated-image" />
              <h2 className="top-text">{topText}</h2>
              <h2 className="bottom-text">{bottomText}</h2>
              <button className="download-btn" onClick={() => downloadMeme(image, topText, bottomText)}>
                ⬇️
              </button>
              {captionLoading && (
                <div className="caption-loader">
                  <div className="loader-text">Loading...</div>
                </div>
              )}
            </div>
          ) : (
            <div className="placeholder">Image will appear here</div>
          )}
        </div>
        <div className="controls" style={{ height: '100%' }}>
          <div className="input-group">
            <label htmlFor="prompt">Prompt</label>
            <div className="input-wrapper">
              <input
                id="prompt"
                type="text"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Enter your image prompt"
              />
              <button className="clear-input" onClick={() => setPrompt('')}>×</button>
            </div>
          </div>
          <div className="style-buttons">
            {['None', 'Anime', 'Chibi', 'Hand-Drawn', 'Pixel', 'Simpsons', 'CCTV', 'Old Game'].map((style) => (
              <button
                key={style}
                className={`style-button ${selectedStyle === style ? 'active' : ''}`}
                onClick={() => setSelectedStyle(style)}
              >
                {style}
              </button>
            ))}
          </div>
          <button 
            className="generate-btn"
            onClick={generateImage} 
            disabled={loading || remainingImages <= 0 || isGenerating}
          >
            {isGenerating ? 'Generating...' : 'Generate'}
          </button>
          <div className="input-group">
            <label htmlFor="topText">Top Text Overlay</label>
            <div className="input-wrapper">
              <input
                id="topText"
                type="text"
                value={topText}
                onChange={(e) => setTopText(e.target.value)}
                placeholder="Enter top text"
              />
              <button className="clear-input" onClick={() => setTopText('')}>×</button>
            </div>
          </div>
          <div className="input-group">
            <label htmlFor="bottomText">Bottom Text Overlay</label>
            <div className="input-wrapper">
              <input
                id="bottomText"
                type="text"
                value={bottomText}
                onChange={(e) => setBottomText(e.target.value)}
                placeholder="Enter bottom text"
              />
              <button className="clear-input" onClick={() => setBottomText('')}>×</button>
            </div>
          </div>
          <button 
            className="generate-captions-btn"
            onClick={generateCaptions} 
            disabled={loading || !image || remainingCaptions <= 0 || isGenerating}
          >
            {isGenerating ? 'Generating...' : 'Generate captions with AI'}
          </button>
          <div className="today-limit">
            Images: {remainingImages}/12 | Captions: {remainingCaptions}/15
          </div>
          <div className="recent-images">
            <h3>Recent Images</h3>
            <div className="image-grid">
              {recentImages.map((imgUrl, index) => (
                <div key={index} className="recent-image-container">
                  <img src={imgUrl} alt={`Recent ${index + 1}`} className="recent-image" />
                  {recentCaptions[index] && (
                    <div className="recent-image-caption">
                      <p>{recentCaptions[index].top}</p>
                      <p>{recentCaptions[index].bottom}</p>
                    </div>
                  )}
                  <button 
                    className="recent-download-btn" 
                    onClick={() => downloadMeme(imgUrl, recentCaptions[index]?.top, recentCaptions[index]?.bottom)}
                  >
                    ⬇️
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
